.container {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  // padding: 3px 6px;
  border-radius: 2px;
  color: #90929a;
  margin-right: 12px;
  align-self: center;
  // padding-right: 24px;
}

.container--small {
  font-size: 12px;
}

.icon {
  opacity: 0.4;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  margin-right: 3px;
  margin-top: 1px;
}

.icon--active {
  opacity: 1;
}

.divider {
  border-left: 1px solid rgba(#90929a, 0.2);
  height: 14px;
  width: 1px;
  padding: 0 2px;
}

.tooltip {
  font-size: 12px;
  padding: 6px 8px;
  background-color: #000;
  border-radius: 0;
}

.tooltip-arrow:before {
  background-color: #000;
}
