@import "./reset.css";

*,
body,
html {
  font-family: Soleil, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 300;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.4s, color 0.4s, border-color 0.4s;

  &:active,
  &:focus {
    color: inherit;
  }
}

strong {
  font-weight: bold;
}

// copy pasting old stylesheet

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-family: Soleil, sans-serif;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

body {
  text-rendering: optimizeLegibility;
}

a {
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.4s, color 0.4s, border-color 0.4s;
}

a,
a:active,
a:focus {
  color: inherit;
}

form input {
  line-height: 30px;
  padding: 0 5px 0 5px;
  border: 1px solid #e0e0e0;
}

.no-scroll {
  overflow: hidden;
}

.side-padding {
  padding: 0 24px;
}

.header-breadcrumb-wrapper {
  display: inline-block;
  margin-left: 21px;
}

.breadcrumb {
  position: relative;
  z-index: 6;
}

.breadcrumb-item {
  padding: 0 5px;
  display: flex;
  align-items: center;
}

.header-breadcrumb-wrapper .breadcrumb {
  font-size: 15px;
}

.breadcrumb-large {
  font-size: 24px;
}

.menu {
  font-size: 12px;
  color: #90929a;
  list-style: none;
  margin: 0 -18px;
}

.menu-item-wrapper {
  display: inline-block;
  padding: 0 18px;
}

.menu-item {
  line-height: initial;
  display: block;
  padding: 10px 0;
  border-bottom: 3px solid transparent;
}

.menu-item:hover {
  color: #fd5f1f;
}

.active .menu-item {
  color: #fd5f1f;
  border-color: #fd5f1f;
}

.collapsible,
.content,
.queries + .three-column-layout,
.query-dropdown-option,
.item-button,
.analytics-item,
.organiser-item,
.item {
  border-top: 1px solid #e0e0e0;
}

.collapsible:first-child,
.query-dropdown-option:first-child,
.organiser-item:first-child,
.item:first-child {
  border-top: 0;
}

.stat-item {
  white-space: nowrap;
}

.stat,
.organiser-count {
  background-color: #e0e0e0;
  display: inline-block;
  border-radius: 10px;
  padding: 8px 15px;
  font-size: 16px;
  margin-left: 8px;
  text-align: center;
}

.three-column-layout {
  position: fixed;
  top: 147px;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateZ(0);
}

.scroller,
.three-column-flex,
.three-column-flex > div {
  height: 100%;
}

.scroller {
  position: relative;
  overflow: auto;
  overflow-x: hidden;
}

.two-column-layout {
  top: 147px;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateZ(0);
}

.scroller,
.two-column-flex,
.two-column-flex > div {
  height: 100%;
}

.search-input .form-group,
.three-column-flex,
.organiser-item {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.three-column-flex > * {
  vertical-align: top;
}

.facets,
.organiser {
  width: 320px;
  display: inline-block;
  border-left: 1px solid #e0e0e0;
}

.organiser-item {
  color: #232736;
  padding: 10px 30px;
  align-items: center;
  min-height: 53px;
}

.organiser-item:hover,
.organiser-item.active {
  background-color: #ececee;
}

.organiser-label {
  flex: 1;
}

.organiser-count {
  padding: 6px 12px;
  font-size: 14px;
  min-width: 40px;
}

.items {
  width: 620px;
  display: inline-block;
  border-left: 1px solid #e0e0e0;
  position: relative;
  padding-top: 40px;
}

.items-sort {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  padding: 0 5px;
  height: 40px;
  z-index: 2;
}

.items-count {
  display: inline-block;
  float: right;
}

.sorter {
  padding: 10px;
  color: #90929a;
  display: inline-block;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.4s 1ms, visibility 1ms;
}

.sorter i {
  margin-left: 10px;
}

.item {
  padding: 10px 15px;
  display: block;
}

.item.active,
.item:hover {
  background-color: #f5f6f7;
}

.item-title-small {
  font-size: 14px;
  max-height: 21px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.item-title {
  font-size: 16px;
}

.item-text {
  font-size: 12px;
  color: #90929a;
  /*display: none;*/
}

/*.item:hover .item-text {*/
/*    display: block;*/
/*}*/

.item-provider-value {
  color: #35383f;
}

.item-url > span {
  width: 320px;
  word-wrap: break-word;
}

.item-attributes {
  font-size: 12px;
  color: #90929a;
  padding: 1px 50px 1px 0; /*5px 50px 5px 0;*/
  margin: 0 -13px;
  position: relative;
}

.item-attributes > * {
  display: inline-block;
  padding: 0 13px;
}

.item-flag {
  right: 0;
  top: 0; /*10px;*/
  position: absolute;
}

.item-flag:hover {
  color: #2389e4;
}

.item-detail {
  flex: 1;
  border-left: 1px solid #e0e0e0;
  position: relative;
  overflow: visible;
  padding-bottom: 58px;
}

.item-detail .scroller {
  padding: 20px 25px;
}

.item-full-text {
  margin-top: 15px;
  white-space: pre-wrap;
}

.item-button {
  text-align: right;
  padding: 10px 30px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
}

.item-button i {
  margin-right: 10px;
}

.searches-wrapper .three-column-layout {
  /*top: 272px*/
  border-top: 1px solid #e0e0e0;
  top: 215px;
}

.query-dropdown,
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown .button i {
  margin-left: 8px;
}

.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  min-width: 160px;
  padding-top: 5px;
  text-align: left;
  color: #35383f;
}

.query-dropdown-left .query-dropdown-options,
.dropdown-left .dropdown-options {
  left: 0;
}

.query-dropdown-right .query-dropdown-options,
.dropdown-right .dropdown-options {
  right: 0;
}

form.queryForm > div > div > input[type="text"] {
  border: inherit;
}

.form-buttons {
  margin-top: 20px;
}

.dropdown-options-bg {
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  border: 1px solid #e0e0e0;
}

.dropdown-option {
  padding: 9px 15px;
  white-space: nowrap;
  display: block;
  position: relative;
}

.dropdown-option > * {
  position: relative;
}

.dropdown-option::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #f5f6f7;
  opacity: 0;
  transition: opacity 0.4s;
}

.dropdown-button .dropdown-option::before {
  background-color: #e7f2fc;
}

.dropdown-option.active::before,
.dropdown-option:hover::before {
  opacity: 1;
}

.dropdown-option > i {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 11px;
}

.queries {
  padding: 12px 0;
  z-index: 4;
  position: relative;
}

.queries > * {
  vertical-align: top;
}

.query-dropdown-selected {
  background-color: #fff;
  padding: 4px 30px 4px 15px;
  display: inline-block;
  border-radius: 2px;
  width: 500px;
}

.query-remove,
.query-dropdown-selected i {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.query-dropdown-selected > span,
.query-dropdown-option > span {
  vertical-align: baseline;
  margin: 0 3px;
}

.query-divider {
  color: #90929a;
  text-transform: uppercase;
  font-size: 10px;
}

.query-dropdown-options {
  position: relative;
  top: 100%;
  min-width: 100%;
  max-height: 70%;
  max-height: 70vh;
  overflow-y: scroll;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
}

.query-dropdown-option {
  padding: 16px 27px 16px 12px;
  line-height: 1;
  background-color: #fff;
  position: relative;
  transition: background-color 0.4s;
  white-space: nowrap;
}

.query-dropdown-option:hover {
  background-color: #f6f6f6;
}

.query-full-row-click {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.query-remove {
  padding: 8px 5px;
  color: #90929a;
  right: 7px;
  font-size: 12px;
}

.query-remove:hover {
  color: #2389e4;
}

.query-dropdown + .button {
  margin-left: 10px;
}

.searches-and-providers {
  background-color: #e0e0e0;
  margin: 0 -30px;
  padding: 8px 20px 8px;
  position: relative;
  z-index: 5;
}

.provider-item {
  display: inline-block;
  padding: 10px 21px; /*12px 21px;*/
  background-color: #cacaca;
  border-radius: 3px 3px 0px 0px;
}

.provider-item.active {
  background-color: #f5f6f7;
}

.searches-and-providers > * {
  vertical-align: top;
  margin-left: 10px;
}

.collapsible {
  padding: 0 30px;
}

.collapsible-title {
  font-weight: normal;
}

.checkbox-input {
  display: none;
}

.checkbox-group {
  /* margin: -5px -15px 0; */
  color: #90929a;
}

.checkbox-item {
  display: inline-block;
  width: 100%; /* 50% for 2 col */
}

.checkbox-item-trigger {
  display: block;
  padding: 5px 15px 0 20px;
  /*font-size: 10px;*/
  line-height: 18px;
  position: relative;
  transition: color 0.4s;
}

.checkbox {
  position: absolute;
  left: 0;
  top: 9px;
  width: 12px;
  height: 12px;
  border: 1px solid rgba(143, 145, 153, 0.5);
  border-radius: 1px;
  color: #fff;
  transition: background-color 0.4s;
}

.checkbox i {
  position: absolute;
  left: 0;
  top: 0;
  transition: opacity 0.4s;
  opacity: 0;
  font-size: 9px;
}

input[type="checkbox"]:checked + .checkbox-item-trigger .checkbox {
  background-color: #90929a;
}

input[type="checkbox"]:checked + .checkbox-item-trigger .checkbox i {
  opacity: 1;
}

input[type="checkbox"]:checked + .checkbox-item-trigger {
  color: #35383f;
}

.collapsible-trigger {
  display: block;
  padding: 9px 0;
  position: relative;
}

.collapsible-with-dot .collapsible-trigger::before {
  content: "";
  position: absolute;
  left: -8px;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 3px;
  background-color: #fd5f1f;
}

.collapsible-trigger i {
  position: absolute;
  right: 0;
  top: 60%; /* 50% */
  transform: translateY(-50%);
  transition: transform 0.4s;
}

.collapsible-trigger:hover {
  color: #fd5f1f;
}

.collapsible-select-wrapper {
  margin: 3px -7px 0;
  padding-bottom: 15px;
}

.collapsible-select-item {
  display: inline-block;
  padding: 7px;
  color: #2389e4;
  font-size: 12px;
}

.collapsible .form-group {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s;
}

.collapsible-show .form-group {
  max-height: 1000px;
}

.collapsible-show .collapsible-trigger i {
  transform: translateY(-50%) rotate(180deg);
}

.collapsible .barchart {
  margin: -30px 0 0 -15px;
}

div[type="bar"] {
  min-height: 0 !important;
}

.react-bootstrap-daterangepicker-container {
  cursor: pointer;
}

.search-input,
.date-range-label {
  color: #90929a;
  font-size: 12px;
}

.date-input {
  order: 1;
  padding: 0px 0px 16px 30px;
}

span.new-search,
.date-input i {
  color: #2389e4;
}

.date-input input {
  border: 0px;
  background: #f5f6f7;
  font-size: 14px;
  color: #90929a;
  padding-top: 10px;
}

.textbox {
  background-color: transparent;
  display: block;
  width: 100%;
  border: 0;
  line-height: normal;
  -webkit-appearance: none;
  border-radius: 0;
  padding: 10px 12px;
}

.search-nav > *,
.search-matches > * {
  display: inline-block;
  white-space: nowrap;
}

.search-count {
  padding: 0 9px;
}

.search-nav > a {
  padding: 6px;
}

.clear-input {
  padding: 6px 12px 6px 6px;
}

.search-input a:hover {
  color: #35383f;
}

.breadcrumb-dropdown {
  min-width: 265px;
  display: inline-block;
}

.new-search i {
  margin-right: 8px;
  font-size: 12px;
}

.no-results {
  text-align: center;
}

.logout {
  position: absolute;
  right: 0px;
  margin: 10px;
}

.projects {
  margin: auto;
  max-width: 960px;
  margin: 35px 0 0 20px;
}

.project {
  margin: 35px 0 0 20px;
  display: flex;
}

.project-left {
  flex: 60%;
}

.project-right {
  flex: 40%;
}

div.listings {
  overflow: scroll;
  max-height: 80vh;
}

.white-box,
div.new-search {
  margin: 30px;
  padding: 20px 25px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
}

.island-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.island {
  max-width: 50%;
}

.providers,
.searches {
  display: inline-block;
}

.searches {
  line-height: 34px;
  font-size: 16px;
}

.selected-facets {
  padding: 5px 0 0 0;
  list-style: none;
  overflow: hidden;
}

.selected-facets .facet {
  float: left;
  font-size: 12px;
  padding: 5px 5px 0 0;
  color: #90929a;
}

.selected-facets .facet .key {
  color: #000000;
}

.MuiAccordionSummary-content.Mui-expanded,
.MuiAccordionSummary-content {
  margin: 0 !important;
}

.MuiAccordionSummary-root,
.MuiAccordionDetails-root {
  padding: 0 !important;
  margin: 0;
}

.MuiAccordionSummary-root.Mui-expanded,
.MuiAccordionSummary-root {
  min-height: 0px !important;
}

.searchRow:hover .searchItemControls {
  opacity: 1;
}

mark {
  color: inherit;
  background-color: #ffdc74;
}

.simplebar-content-wrapper {
  position: static !important;
}
