@import "../../styles/colours.scss";

$provider-max-width: 12rem;
$span-max-width: 10em;

@mixin bg--animate {
  transition: background-color 0.25s ease-out;
}

.container {
  padding: 0px 15px;
  background-color: #ebedef;
  // min-height: 80px;
  display: flex;
  font-size: 12px;
  overflow-y: hidden;
  overflow-x: auto;
}

.providers {
  text-transform: capitalize;
  display: flex;
  height: 100%;
}

.tab {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include bg--animate();

  &:hover {
    background-color: rgba(#d6d9db, 0.5);
  }

  .tab-header {
    display: flex;
    align-items: center;
    padding: 6px 10px;
    opacity: 0.8;
    font-size: 12px;
    &.label {
      margin-right: 5px;
    }
    &.selectable:hover {
      cursor: pointer;
      font-weight: bold;
    }
    &.selected {
      font-weight: bold;
      font-size: 14px;
    }
  }
}

.tab {
  border-radius: 4px;
  border-top: 3px solid #e0e0e0;
  @include bg--animate();
}

.tab:hover,
.tab--child-active {
  &.tab--news {
    border-top: 3px solid $color-news;
  }

  &.tab--compliance {
    border-top: 3px solid $color-compliance;
  }

  &.tab--records {
    border-top: 3px solid $color-records;
  }

  &.tab--related {
    border-top: 3px solid $color-related;
  }
}

.tab--child-active {
  background-color: rgba(#24272d, 0.1);
  &:hover {
    background-color: rgba(#24272d, 0.05);
  }
}

.subtabs {
  display: flex;
}

.provider {
  display: flex;
  align-items: center;
  min-width: 8rem;                // plus see varied .tab--nN widths below
  //max-width: $provider-max-width; //
  padding: 8px 6px 8px 9px;
  white-space: nowrap;
  cursor: pointer;
  @include bg--animate();

  &:hover {
    background-color: rgba(#ffffff, 0.2);
    .remove {
      opacity: 1;
    }
  }
}

.remove {
  opacity: 0;
}

.tab--active,
.provider--active {
  border-radius: 3px 3px 0px 0px;
  border: 1px solid #000;
  border-bottom-color: #e0e0e0;
  background-color: #fff;
  margin-bottom: -1px;
  z-index: 1;
  &:hover {
    background-color: #fff;
  }
}

.label {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 14px;
  opacity: 0.8;
  padding-right: 12px;

  span {
    display: inline-block;
    max-width: $span-max-width; // plus see varied .tab--nN widths below
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.button {
  height: 2rem;
  width: 2rem;
}

.icon {
  height: 1rem;
  width: 1rem;
}

.search {
  display: flex;
  align-items: center;
}

// tighten tab widths for many tabs
@for $i from 1 through 8 {// < make sure '8' is in the .tsx code
  .tab--n#{$i} {
    .provider {
      min-width: 2rem;
      //max-width: ($provider-max-width - ($i * .9rem));
      .label {
        padding-right: (12px - ($i * 1.4px));
        > span {
          max-width: ($span-max-width - ($i * .9em));
        }
      }
    }
  }
}

.tab--many {
  .provider {
    min-width: 2rem;
    max-width: 4rem;
    .label {
      padding-right: 0;
      span {
        max-width: 2em;
      }
    }
  }
}
