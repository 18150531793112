@import "../../styles/mixins.scss";

.placeholder {
  display: block;
  width: 100%;
  color: #90929a;
  text-align: center;
  padding: 1rem;
  font-size: 0.8rem;
}

.item-controls {
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.item-control-button {
  font-size: 16px;
  width: 20px;
  height: 20px;
  margin-left: 4px;
}

.icon {
  color: #fd5f1f;
  margin-right: 4px;
  height: 16px;
  width: 16px;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px 25px;
  width: 35%;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #fff;
  color: #90929a;
}

.modal-content {
  display: flex;
  width: 100%;
}

.modal-inner {
  width: 100%;
}

.modal-title {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  color: #35383f;
  line-height: 1.3;
}

.modal-controls {
  margin-top: 1.5rem;
  margin-left: auto;
}

.button {
  text-transform: none;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 10px 12px;
  line-height: 1;
  font-size: 14px;
}

.button-primary {
  background-color: rgba(35, 137, 228, 1);
  color: #fff;
  border: none;
  margin-left: 0.5rem;
  &:hover {
    background-color: rgba(35, 137, 228, 0.6);
  }
}

.label {
  text-transform: uppercase;
  font-size: 10px;
  color: #90929a;
  margin-top: 20px;
  display: block;
}

.label-helper {
  font-size: 14px;
  color: #35383f;
}

.input {
  width: 100%;
  border: 1px solid #e0e0e0;
  margin-top: 4px;
  margin-bottom: 2px;
  padding: 12px;
  font-size: 12px;
  & input {
    padding: 0;
  }
}

.arrow {
  width: 16px;
  margin-right: 8px;
  text-align: right;
}

.cheat-sheet {
  color: #90929a;
  display: flex;
  height: 0%;
  align-items: center;
  margin-left: 0.3rem;
}

.term {
  color: #000;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  th {
    font-weight: normal;
    text-align: left;
    text-transform: uppercase;
    font-size: 10px;
    padding: 7px;
    color: #90929a;
    border-bottom: 1px solid #e0e0e0;
  }
  td {
    margin: 0;
    padding: 12px;
    border: 0;
    font-size: 13px;
    border-bottom: 1px solid #e0e0e0;
    line-height: 14px;
  }
}

.row,
.row--search,
.row--provider,
.row--query {
  @include fade-in;
  &:hover {
    .item-controls {
      opacity: 1;
    }
  }
}

.row--search {
  background-color: #fff;
}

.row--provider {
  background-color: #fafafb;
  td:first-child {
    padding-left: 32px;
  }
}

.row--query {
  td:first-child {
    padding-left: 72px;
  }
}

.facets {
  padding: 5px 0 0 0;
  overflow: hidden;
  .facet {
    display: inline-block;
    font-size: 12px;
    padding: 5px 5px 0 0;
    color: #90929a;
  }
  .key {
    color: #000000;
  }
}

.icon-button {
  height: 24px;
  width: 24px;
  margin-left: 6px;
  border-radius: 3px;
  border: 1px solid lightgray;
}

.providers {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.checkbox-adverse-terms {
  margin-bottom: 0.8rem;
  label {
    & + div {
      padding-left: 5px;
      margin-bottom: 4px;
      & > label {
        margin-top: 5px;
        margin-bottom: 4px;
        & + div {
          margin-left: 0.2rem !important;
          & > div > div {
            margin-left: 4px !important;
            &:first-child {
              margin-left: 0 !important;
            }
          }
        }
      }
    }
  }
}
