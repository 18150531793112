.wrap {
  padding: 80px 20px;
  display: flex;
  justify-content: center;
}

.container {
  border: 1px solid black;
  width: 600px;
  max-width: 650px;
}

.header {
  display: flex;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #ccc;
  justify-content: space-between;

  svg {
    width: 24px;
    height: 24px;
    margin: 0 12px;
    fill: #fb6020;
  }

  h1 {
    font-size: 20px;
  }
}

.content {
  padding: 12px;

  p, li {
    color: #555;
  }

  ol {
    margin-left: 25px;

    li {
      list-style: decimal;
      margin-bottom: 10px;
    }
  }
}