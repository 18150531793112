.add {
  cursor: pointer;
  color: #0f68b7;
}
.complete {
  color: rgba(53, 56, 63, 0.7);
}
.add, .complete {
  width: 18px;
  height: 18px;
  margin-right: 0.25em;
}