.grid {
  display: grid;
  grid-template-columns: 2fr 5fr 5fr;
  margin: 0;
  border-top: 1px solid #e0e0e0;
  min-height: 0;
  background-color: #fff;
}

.grid--compliance {
  grid-template-columns: 2fr 5fr 50%;
}

.spinner-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%);
}

.spinner {
  color: red;
}

.term {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 8px;
}

.facets--terms {
  order: 2;
}

.facets--language {
  order: 3;
}
