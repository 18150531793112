@mixin fade-in {
  opacity: 1;
  animation-name: fadeIn;
  animation-delay: 0s;
  animation-duration: 0s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
