.button {
  height: 2rem;
  min-width: 2rem;
  text-transform: none;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 10px 12px;
  line-height: 1;
  font-size: 14px;
};
.button_icon {
  height: 1rem;
  width: 1rem;
};
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
};
.modal_wrap {
  display: flex;
  flex-direction: column;
  padding: 20px 25px;
  width: 35%;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #fff;
  color: #90929a;
};
.modal_title {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;;
  color: #35383f;
  line-height: 1.3;
};
.modal_controls {
  margin-top: 1.5rem;
  margin-left: auto;
};
.modal_inner_alert {
  width: 100%;
};
.modal_inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #90929a;
};
.modal_content {
  display: flex;
};
.modal_text {
  font-size: 14px;
};
.modal_icon_wrap {
  width: 2.5rem;
  height: 2.5rem;
  background-color: rgba(245, 36, 40, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  flex-shrink: 0;
  margin-right: 1rem;
};
.modal_icon {
  color: rgba(220, 38, 38, 1);
  width: 1.5rem;
  height: 1.5rem;
};

.button_primary {
  color: #fff;
  border: none;
  margin-left: 0.5rem;
  background-color: #2389e4;
  &:hover {
    background-color: #0f68b7;
  };
};
.button_alert {
  background-color: rgba(245, 36, 40, 1);
  color: #fff;
  border: none;
  margin-left: 0.5rem;
  &:hover {
    background-color: rgba(245, 36, 40, 0.6);
  };
};