.container {
  display: flex;
}

.date-nav {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.date-month {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-row: 1;
  padding: 0 1em;
}

.date-month__inner {
  display: flex;
  align-items: center;
}

.pagination-button {
  height: 1.5rem;
  width: 1.5rem;
  font-size: 12px;
}

.pagination-icon {
  height: 0.75rem;
  width: 0.75rem;
}

.dropdown {
  font-size: 14px;
  div {
    padding-right: 20px !important;
  }
}

.input {
  padding: 0.5em 1em;
  input {
    text-align: center;
  }
}
