.dropdown {
  display: flex;
  justify-content: space-between;
}
.controls {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.content {
  margin-top: 10px;
  padding-left: 0.5em;
  background: rgba(53, 56, 63, 0.1);

  p:not(:last-of-type) {
    margin-bottom: 5px;
  }

  table {
    //background: white;

    td {
      padding-left: 8px;
      border-right: 1px solid #e0e0e0;
    }
  }
}
.no-indent {
  padding-left: 0;
}