.button-icon {
  height: 1rem;
  width: 1rem;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  display: grid;
  grid-template-rows: 2rem 1fr 2rem;
  gap: 18px;
  padding: 20px 25px;
  max-height: 80%;
  width: 35%;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #fff;
  color: #90929a;
}

.modal--alert {
  grid-template-rows: 1fr 2rem;
}

.modal--large {
  width: 45%;
}

.title {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  color: #35383f;
  line-height: 1.3;
}
.controls {
  // margin-top: 1.5rem;
  margin-left: auto;
}

.inner--alert {
  width: 100%;
}

.inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #90929a;
  padding-right: 2px;
  label:first-child {
    margin-top: 0;
  }
}

.content {
  display: flex;
  min-height: 0;
}

.text {
  font-size: 14px;
}

.icon__wrap {
  width: 2.5rem;
  height: 2.5rem;
  background-color: rgba(245, 36, 40, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  flex-shrink: 0;
  margin-right: 1rem;
}

.icon {
  color: rgba(220, 38, 38, 1);
  width: 1.5rem;
  height: 1.5rem;
}

.button {
  text-transform: none;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 10px 12px;
  line-height: 1;
  font-size: 14px;
}

.button--primary {
  color: #fff;
  border: none;
  margin-left: 0.5rem;
  background-color: #2389e4;
  &:hover {
    background-color: #0f68b7;
  }
}

.button--alert {
  background-color: rgba(245, 36, 40, 1);
  color: #fff;
  border: none;
  margin-left: 0.5rem;
  &:hover {
    background-color: rgba(245, 36, 40, 0.6);
  }
}
