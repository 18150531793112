.button {
  display: flex;
  align-items: center;
  padding: 9px 12px;
  font-size: 15px;
  background-color: #fff;
  margin-right: 20px;
  min-width: 160px;
  gap: 4px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  z-index: 3;
  line-height: 1.2;
  span {
    text-overflow: ellipsis;
    max-width: 12rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
}

.modal {
  position: fixed;
  margin-top: 8px;
  width: calc(100% - 30px);
  background-color: #fff;
  z-index: 3;
  border-radius: 6px;
}
