.article {
  font-size: 1rem; // master font size
  padding: 0.75rem 1rem;
  overflow: auto;
  max-height: 100%;
  overflow-x: hidden;
  // pretty for screen
  min-height: 20rem;
  max-width: 60rem;
  border: 1px solid #ddd;
  margin: 1rem auto 5rem auto;
  color: #111;
  box-shadow: 1px 3px 11px 1px rgba(0,0,0,0.2);
  @media print {
    font-size: 0.85rem; // master font size for print
    // undo pretty for screen for print
    min-height: none;
    max-width: none;
    border: none;
    margin: 0;
    color: #000;
    box-shadow: none;
    page-break-after: always;
  }
}
.title {
  font-size: 1.75em;
  line-height: 1.3;
  margin: 0 0 .5em 0;
}
.detail {
  font-size: 1.1em;
  line-height: 1.3;
  margin: 0 0 1.3em 0;
  border-bottom: 1px solid #90929a;
  padding: 0 0 1.2em 0;
  @media print {
    padding: 0 0 0.5em 0;
  }
}
.source-name {
  margin-right: .3em;
}
.date {
  margin-left: .3em;
}
.content {
  font-size: 1em;
  line-height: 1.6;
  margin: 0 0 1em 0;
  overflow: hidden;
  white-space: pre-line;
}
.url {
  font-size: 1em;
  margin: 0 0 1em 0;
  padding: 0.5em 0 0 0;
  overflow-wrap: break-word; // ensure urls wrap
  word-wrap: break-word; //
  p + & {
    // for case of content and url
    margin-top: 1.7em;
    padding-top: 1.5em;
    border-top: 1px solid #90929a;
  }
}