.treeview {
  font-size: 12px;
  color: #90929a;

  .treeview,
  .tree-item {
    margin-left: 12px;
  }
}

.treeview,
.tree-item {
  // margin-top: 2px;
  margin-bottom: 2px;
}

.tree-item--disabled {
  opacity: 0.4;
}

.treeview-label {
  margin-bottom: 2px;
}

.treeview-label__icon {
//   margin-right: 6px;
}
