.container {
  position: relative;
  display: grid;
  grid-template-columns: 2fr 3fr 5fr;
  margin: 0;
  border-top: 1px solid #000;
  min-height: 0;
  background-color: #fff;
}
.news-container {
  grid-template-columns: 2fr 5fr 5fr !important;
}

.spinner {
  position: relative;
  top: auto;
  left: auto;
}