.container {
  width: calc(100% - 1px);
}

.row {
  transition: all ease-in-out 250ms;
  border: 1px solid transparent;
  &:hover {
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #c4c4c4;
    box-shadow: 0px 3px 12px #24272d1f;
  }
}
.row--name {
  max-width: 20vw;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cell {
  display: flex;
  gap: 8px;
  align-items: center;
}

.provider {
  display: flex;
  align-items: center;
  gap: 4px;
}

.spinner {
  margin-left: 3px;
  margin-top: 3px;
}

.heading {
  color: rgba(#35383f, 0.5);
  font-size: 24px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 4px;

  @media (max-width: 450px) {
    margin-bottom: 10px;
  }
}
.heading-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 450px) {
    flex-direction: column;
    margin-bottom: 24px;
  }
}

.error-icon {
  width: 18px;
  height: 18px;
}

.status-icon {
  width: 24px;
  max-height: 24px;
  vertical-align: middle;
}

.retry-button {
  text-transform: none;
  color: white;
  font-weight: 400;
  background: #0f68b7;
  line-height: 1;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }

  &:hover {
    opacity: 0.8;
    background: #0f68b7;
  }
}

.statusColumn {
  padding: 5px;
}