.results {
  position: relative;
  background-color: #fff;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  overflow: hidden;
  padding: 36px 0 45px 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e0e0e0;
  padding: 0.25rem 1rem;
  position: absolute;
  top: 0;
  width: 100%;

  z-index: 1;
  font-size: 13px;
  height: 36px;
}
