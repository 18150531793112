@mixin search-header-button {
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 12px;
  border-radius: 6px;
  transition: background-color ease-in-out 150ms;
  &:hover {
    background-color: rgba(#ebedef, 0.8);
  }
}

.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  min-height: 6rem;

  @media (max-width: 767px) {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }

  .heading {
    @include search-header-button();
  }
}

.container--findings {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .heading {
    display: flex;
    align-items: center;
    gap: 6px;
    h2 {
      font-size: 24px;
      opacity: 0.5;
    }
    svg {
      margin-top: 2px;
      opacity: 0.4;
      padding: 1px;
      color: rgba(#35383f, 0.8);
    }
  }
  .count {
    padding: 4px 8px;
  }
}

.project,
.link {
  padding: 1rem 1.5rem;
}

.heading {
  display: flex;
  align-items: center;
  gap: 6px;
  h2 {
    font-size: 16px;
  }
  svg {
    margin-top: 2px;
    padding: 1px;
    font-size: 16px;
    color: rgba(#35383f, 0.8);
  }
}

.title {
  color: rgba(#35383f, 0.8);
  font-size: 12px;
  text-transform: uppercase;
}

.subtitle {
  max-width: 17.5vw;
  margin-top: 2px;
  color: #35383f;
  font-size: 16px;
  white-space: nowrap;
  display: flex;
  align-items: center;

  @media (max-width: 1050px) {
    max-width: 4rem;
  }
  @media (max-width: 767px) {
    max-width: 85vw;
  }

  a {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.count {
  margin-top: 2px;
  line-height: 100%;
  padding: 4px 6px;
  border-radius: 1rem;
  color: rgba(#35383f, 0.8);
  background-color: #e8e9e9;
}

.findings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: calc(100% + 2px);
  width: 100%;
  border-radius: 3px 3px 0px 0px;
  border: 1px solid #000;
  border-bottom-color: #e0e0e0;
  background-color: #fff;
  margin-top: -1px;
  margin-bottom: -1px;
  z-index: 1;
  padding: 1rem 1.5rem;
}

.exit {
  @include search-header-button();
}
