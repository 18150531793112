.container {
  position: relative;
  background-color: #fff;
  overflow: hidden;
  padding: 36px 0 45px 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e0e0e0;
  padding: 0.25rem 1rem;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  font-size: 13px;
  height: 36px;
}

.loading {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  height: 4px;
  color: #7bb9f0;
  overflow: hidden;
}

.borders {
  &:after {
    content: "";
    position: absolute;
    border: 1px solid #e0e0e0;
    top: 0;
    height: 100%;
    width: calc(100% - 2px);
    z-index: -1;
  }
}
