.searchbar {
  appearance: none;
  background: white;
  border: 1px solid lightgrey;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  padding: 8.5px 14px;
  cursor: pointer;
  width: 50rem;
  text-align: left;
  overflow-y: hidden;
  
  @media screen and (max-width: 850px) {
    width: 100%;
  }

  p {
    color: #b1b1b1;
    transition: all 0.2s ease;
  }

  .adornment {
    transition: all 0.2s ease;
    overflow-y: hidden;
    position: relative;
    align-self: flex-start;
    margin-top: 3px;
  }
  svg {
    left: -4px;
    transition: all 0.2s ease;
    position: absolute;
    bottom: -50px;
    width: 16px;
    height: 16px;
  }
  &:hover {
    border-width: 2px;
    border-color: #3796fb;

    p {
      padding-left: 16px;
    }

    input {
      padding: 10px 8px;
    }
    svg {
      bottom: -18px;
    }
  }
}