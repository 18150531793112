.heading {
  color: rgba(#35383f, 0.5);
  font-size: 24px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 4px;

  @media (max-width: 450px) {
    margin-bottom: 10px;
  }
}

.row {
  transition: all ease-in-out 250ms;
  border: 1px solid transparent;
  &:hover {
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #c4c4c4;
    box-shadow: 0px 3px 12px #24272d1f;
  }
}

.private {
  color: #fd5f1f;
  margin-left: 4px;
  height: 16px;
  width: 16px;
  opacity: 0.8;
}

.cell-inner {
  display: flex;
  align-items: center;
}

.filters {
  margin: 16px 20px 0 12px;
  display: flex;
}

.people-filter,
.date-filter {
  font-size: 12px;
  & fieldset {
    border-radius: 0;
  }
  &:hover {
    .date-filter--clear {
      visibility: visible;
    }
  }
}

.filter {
  width: 180px;
}

.name-filter,
.date-filter {
  margin-right: 14px;
  input {
    padding: 8.6px 0;
  }
}

.date-filter--clear {
  padding: 4px;
  visibility: hidden;
  margin-right: -5px;
}

.adornment {
  top: auto;
  margin-right: -2px;
}

.indicator {
  font-size: 16px;
  width: 24px;
  height: 24px;

  svg {
    font-size: 17px;
  }
}

.project-column {
  max-width: 3em;

  a {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.my-projects-filter,
.clear-filters {
  font-size: 12px;
  font-weight: medium;
  margin-left: 16px;
  letter-spacing: 0;
  text-transform: none;
  align-self: center;
  cursor: pointer;
  transition: opacity ease 0.3s;

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
}

.hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1;
  margin: -1;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20;
  width: 1;
}

.dropdown-menu {
  border: 1px solid #e0e0e0;
  border-radius: 0;
  box-shadow: none;
  font-size: 15px;
}

.cheat-sheet {
  color: #90929a;
  display: flex;
  height: 0%;
  align-items: center;
  margin-left: 0.3rem;
  // margin-top: 1px;
}

.name-cell {
  width: 40%;
}

.owner-cell {
  width: 30%;
}

.created-cell {
  width: 20%;
}

.control-cell {
  width: 10%;
}

.term {
  color: #000;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.providers {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.checkbox-adverse-terms {
  margin-bottom: 0.8rem;
  label {
    & + div {
      padding-left: 5px;
      margin-bottom: 4px;
      & > label {
        margin-top: 5px;
        margin-bottom: 4px;
        & + div {
          margin-left: 0.2rem !important;
          & > div > div {
            margin-left: 4px !important;
            &:first-child {
              margin-left: 0 !important;
            }
          }
        }
      }
    }
  }
}

.everyone {
  font-style: italic;
  color: #777;
}
