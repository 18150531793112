.label {
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  color: #90929a;
  margin-top: 20px;
  margin-bottom: 4px;
}

.toggle-wrapper {
  margin-left: 0px;
  display: flex;
  align-items: center;
}

.toggle-label {
  text-transform: none;
  font-size: 12px;
  color: #35383f;
  // margin-left: 4px;
}

.toggle {
  width: 32px;
  height: 20px;
  padding: 0;
  display: flex;

  .toggle-thumb {
    color: #fff;
    width: 12px;
    height: 12px;
    box-shadow: none;
  }

  .toggle-inner {
    // padding: 2px;
    color: #90929a;
    &.toggle-checked {
      transform: translateX(12px);
      color: #fff;
      & + .toggle-track {
        opacity: 1;
        background-color: #3796fb;
        border: transparent;
      }
    }
  }

  .toggle-track {
    border: 1px solid #e0e0e0;
    border-radius: 1.6rem;
    opacity: 1;
    background-color: #e0e0e0;
  }
}

.toggle--small {
  width: 24px;
  height: 14px;
  .toggle-thumb {
    width: 8px;
    height: 8px;
  }
  .toggle-inner {
    padding: 3px;
    &.toggle-checked {
      transform: translateX(9.5px);
    }
  }
}

.input {
  width: 100%;
  margin-top: 2px;
  margin-bottom: 2px;
  fieldset {
    border-radius: 0;
  }
  textarea {
    font-size: 14px;
    resize: auto;
  }
  input {
    font-size: 14px;
    padding: 10px 12px;
  }
  &:hover {
    .clear-wrapper {
      opacity: 1;
    }
  }
}

.input--small {
  & input {
    font-size: 12px;
  }
}

.input--search {
  .input__adornment {
    transition: all 0.2s ease;
    overflow-y: hidden;
    position: relative;
    height: 16px;
    width: 0;
    align-self: flex-start;
    margin-top: 3px;
  }
  .input__adornment + textarea {
    margin-left: 8px;
  }
  svg {
    transition: all 0.2s ease;
    position: absolute;
    bottom: -24px;
  }
  &:hover,
  &.input--active {
    .input__start-adornment {
      padding-left: 12px;
    }
    input {
      padding: 10px 8px;
    }
    .input__adornment {
      width: 16px;
    }
    svg {
      bottom: 0;
    }
  }
  .input__start-adornment:hover {
    fieldset {
      border-width: 2px;
      border-color: #3796fb;
    }
  }
}

.input__start-adornment {
  padding-left: 6px;
}

.input__end-adornment {
  padding-right: 6px;
}

.clear-wrapper {
  opacity: 0;
  position: absolute;
  right: 5px;
  background-color: white;
  border-radius: 12px;
  width: 24px;
  height: 24px;
}

.clear {
  font-size: 16px;
  width: 24px;
  height: 24px;
}

.dropdown {
  margin-top: 4px;
  padding: 10px 12px;

  font-size: 15px;
  display: flex;
  font-family: inherit;
  align-items: center;
  .dropdown-inner {
    &:focus {
      background-color: transparent;
    }
    & .MuiSelect-select {
      &:focus {
        background-color: transparent;
      }
    }
  }
}

.dropdown--outlined {
  & fieldset {
    border-radius: 0;
  }
}

.dropdown--transparent {
  background-color: transparent;
  padding: 0.5rem 1rem 0.5rem 0.35rem;
  & fieldset {
    border-radius: 0;
    border: transparent;
  }
}

.dropdown--search {
  background-color: transparent;
  min-width: 240px;
  padding: 0.5rem 1rem 0.5rem 0.35rem;

  & fieldset {
    border-radius: 0;
    border: transparent;
  }

  .dropdown-icon {
    display: none;
  }
}

.dropdown--grey-uppercase,
.toggle-label--grey-uppercase {
  font-size: 11px;
  color: #90929a;
  text-transform: uppercase;
  padding: 0;
  margin-top: 0;
  & fieldset {
    border-radius: 0;
    border: transparent;
  }
}

.dropdown-inner {
  padding: 0;
  margin: 0;
}

.dropdown-menu {
  border: 1px solid #e0e0e0;
  border-radius: 0;
  box-shadow: none;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.checkbox-wrapper--facet {
  .checkbox-label {
    color: #90929a;
    font-size: 12px;
    // margin-bottom: 1px;
  }
}

.checkbox-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1em;
}

.checkbox-label--full-width {
  width: 100%;
}

.checkbox-wrapper--small {
  .checkbox-label {
    font-size: 12px;
  }
}

.checkbox {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  margin: 0;
}

.checkmark {
  position: relative;
  width: 14px;
  min-width: 14px;
  height: 14px;
  min-height: 14px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  margin-right: 4px;
}

.checkbox-wrapper:hover .checkbox:checked ~ .checkmark {
  cursor: pointer;
  transition: all 0.15s ease-out;
  border-color: #90929a;
}

.checkbox-wrapper:hover .checkbox ~ .checkmark {
  cursor: pointer;
  transition: all 0.15s ease-out;
  border-color: rgba(0, 0, 0, 0.4);
}

.checkbox:checked ~ .checkmark {
  border-radius: 3px;
  background-color: #90929a;
  border-color: #90929a;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox:checked ~ .checkmark:after {
  display: block;
}

.checkmark:after {
  left: 4px;
  top: 2px;
  width: 3px;
  height: 6px;
  border: solid white;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

.checkbox--selected {
  width: 1.2rem;
  height: 1.2rem;
}

.treeview {
  font-size: 12px;
  color: #90929a;

  .treeview,
  .tree-item {
    margin-left: 12px;
  }
}

.treeview,
.tree-item {
  // margin-top: 2px;
  margin-bottom: 2px;
}

.tree-item--disabled {
  opacity: 0.4;
}

.treeview-label {
  margin-bottom: 2px;
  cursor: default;
}

.treeview-label__icon {
  margin-right: 6px;
}
