.button {
  color: #fff;
  background-color: #2389e4;
  padding: 6px 12px;
  border-radius: 2px;
  position: relative;
  transition: background-color 0.15s ease;
  z-index: 0;
  &:hover {
    background-color: #0f68b7;
  }
  &:disabled {
    color: #90929a;
    background-color: #e4e4e6cb;
  }
  .label {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.button--small {
  font-size: 12px;
  padding: 5px 8px;
}

.button--full-width {
  width: 100%;
}

.button--secondary {
  color: #2389e4;
  background-color: transparent;
  &:hover {
    background-color: #e9f3fc;
  }
  &:focus {
    color: #2389e4;
  }
}

.button--alert {
  background-color: rgba(245, 36, 40, 1);
  &:hover {
    background-color: rgba(245, 36, 40, 0.6);
  }
}

.button--header {
  background-color: transparent;
  i {
    margin-top: 2px;
  }
  &:hover {
    background-color: rgba(112, 112, 112, 0.25);
  }
}

.button--finding,
.button--finding-active {
  background-color: transparent;
  border-radius: 16px;
  border: 1px solid #e0e0e0;
}

.button--finding {
  .label {
    color: #000;
  }
  &:hover {
    .label {
      color: #2389e4;
    }
    border: 1px solid transparent;
    background-color: rgba(#2389e4, 0.1);
  }
}

.button--finding-active {
  color: #000;
  &:hover {
    background-color: transparent;
    .label {
      visibility: hidden;
    }
    .remove {
      visibility: visible;
      color: #fd5f1f;
    }
  }
}

.remove {
  visibility: hidden;
  position: absolute;
  margin: auto;
}

.label--loading {
  visibility: hidden;
}

.spinner {
  visibility: visible;
  position: absolute;
  margin: auto;
}

.icon-button {
  transition: opacity 300ms ease;
  font-size: 16px;
  width: 20px;
  height: 20px;
  padding: inherit;
}
