@import "../../styles/mixins.scss";

$link-color: #2389e4;

.media-print {}
.media-screen {}

.header {
  padding-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
}

.header--compliance {
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 16px 24px;
  background-color: #fff;
  margin: -16px -24px 0 -24px;

  display: flex;
  justify-content: space-between;
  font-size: 13px;

  .header-details,
  .header-details span {
    font-size: 14px;
    color: rgba(#35383f, 0.8);
    display: flex;
    align-items: center;
  }

  .header-details {
    gap: 16px;
  }

  span {
    gap: 2px;
  }
}

.header-controls {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  justify-content: space-between;
}

.primary-controls {
  display: flex;
  align-items: center;
}

.container {
  background-color: #fff;
  padding: 1rem 1.5rem;
  min-height: 100%;
  max-height: 100%;
  min-width: 100%;
  max-width: 100%;
}

.article {
  @include fade-in;
}

.title {
  font-size: 16px;
}

.text {
  font-size: 14px;
}

.secondary-controls {
  display: flex;
  align-items: center;
  margin-top: 4px;
  min-height: 36px;
}

.icon {
  cursor: pointer;
  width: 12px;
  height: 12px;
  margin-bottom: -2px;
  margin-left: 2px;
}

.chip {
  margin-right: 2px;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
}

.snippet {
  font-weight: 500;
  padding-bottom: 20px;
  padding-top: 6px;
  // marginBottom: 20px;
  // borderBottom: 1px solid #e0e0e0;
}

.content {
  margin-top: 1rem;
  overflow: hidden;
  white-space: pre-line;
  font-size: 14px;
}

.no-content {
  color: #999;
}

.content-footer {
  // margin-top: auto;
  margin-top: 1.2rem;
  padding-top: 0.6rem;
  border-top: 1px solid #e0e0e0;
}

.content-links {
  display: flex;
  padding-top: 0.5rem;
}

.content-links > div {
  margin-right: 1rem;
}

.content-links-caption {
  display: block;
  margin-top: 0.5rem;
  font-size: 12px;
}

.footer {
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 1rem 0;
  background-color: #fff;
  z-index: 1;
}

.subtitle {
  font-size: 12px;
  margin-top: 0.4rem;
  color: #90929a;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input {
  font-size: 12px;
}

.paper {
  border: 1px solid #e0e0e0;
  border-radius: 0;
  box-shadow: none;
}

.dropdown {
  font-size: 12px;
  background-color: #f5f6f7;
  min-width: 280px;
  padding: 4px 6px;
}

.chip {
  font-size: 12px;
  margin-right: 4px;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
}

.table {
  margin-bottom: 1rem;
}

.generic-structured-data {
  > ul {
    font-size: 0.875rem;
    list-style: none;
    margin: 0;
    padding: 0;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    li {
      list-style: none;
      margin: 0;
      padding: 0.4rem 0 0 0.5rem;
    }
    b {
      font-weight: 500;
      display: inline-block;
      padding-right: 1em;
      &:after {
        content: ":";
      }
    }
    span {
      font-weight: 400;
      line-height: 1.43;
    }
    a[href] {
      color: $link-color;
    }
    & > li {
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 0.6rem;
      padding-left: 0 !important;
      &:last-child {
        border: 0;
        padding-bottom: 0;
      }
      & > b {
        font-size: 1rem;
      }
    }
    li > ul:first-child > li:first-child {
      padding-top: 0;
    }
    > li > ul {
      > li {
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 0.6rem;
        &:last-child {
          border: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}

.raw-json-data,
.key-value-data {
  background: #eee;
  border: 1px solid #dcdcdc;
  padding: 0.3rem .5rem;
  font-size: 11px;
  position: relative;
}

.raw-json-data {

  span {
    position: absolute;
    top: 0;
    right: 0;
    color: #999;
    padding: 0.3rem;
    font-size: 10px;
    cursor: pointer;
  }
  pre {
    max-width: 100%;
  }
}

.key-value-data {
  span {
    font-weight: 600;
  }
}

.structure {
  margin-bottom: 2rem;

  .structure {
    margin-bottom: -1px !important;
  }

  table {
    width: 100%;

    tr {
      border-top: 1px solid #e0e0e0;
      &:last-child {
        border-bottom: 1px solid #e0e0e0;
      }
    }

    .row-head {
      color: rgba(#35383f, 0.5);
      border-top: none;
    }

    th,
    td {
      padding: 8px 8px 8px 0;
      font-size: 14px;
    }

    th {
      text-transform: uppercase;
      font-size: 12px;
      text-align: left;
      letter-spacing: 0.2px;
      min-width: 75px;
    }
  }
}

.structure-title {
  color: rgba(#35383f, 0.5);
  font-size: 18px;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
}

.structure-sub-title {
  color: rgba(#35383f, 0.5);
  font-size: 15px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  .structure + & {
    margin-top: -0.5rem;
  }
}

.structure-mute {
  color: rgba(#35383f, 0.5);
}

small.structure-mute {
  font-size: 11px;  
}

.structure-row,
.structure-row--source {
  padding: 8px 0;
  border-top: 1px solid #e0e0e0;
  &:last-child {
    border-bottom: 1px solid #e0e0e0;
  }

  span {
    // color: #90929a;
    font-size: 14px;
    margin-right: 0.5em;
  }

  span:not(:first-child) {
    font-weight: 600;
    .structure-mute {
      font-weight: normal;
    }
    &.structure-text {
      font-weight: normal;
    }
  }

  b {
    font-weight: 600;
    margin-right: 0.5em;
  }

  a.data-link {
    margin-right: 0.5em;
  }
}

.structure-row {
  display: grid;
  grid-template-columns: 30% auto;
  a {
    font-size: 12px;
  }
  & + .structure > div {
    margin-top: 0.5rem;
  }

  > .span-row {
    grid-column: 1 / span 2;
    > div {
      margin-left: 5px;
      > span {
        margin-right: 0;
      }
    }
  }
}

.structure-text {
  b {
    font-weight: 600;
  }
  a[href] {
    color: $link-color;
  }
}

.image-wrapper {
  display: flex;
  margin-bottom: 40px;
  gap: 1rem;
  h4 {
    // margin-top: 2px;
    font-size: 16px;
    font-weight: 500;
  }
}

.image-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image,
.image-unavailable {
  width: 100px;
  height: 100px;
  background-color: rgb(245, 246, 247);
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-unavailable {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  background-color: rgb(245, 246, 247);
}

.tags {
  margin-top: 2px;
}

.remove-icon {
  font-size: inherit;
  width: 16px;
  height: 16px;
}

.view-data-control {
  margin-top: 2rem;
  display: block;
  font-size: 12px;
  color: $link-color;
  cursor: pointer;
  & + .raw-json-data {
    margin-top: .5rem;
  }
}

.data-link {
  cursor: pointer;
  color: $link-color;
  &:hover {
    color: $link-color;
    text-decoration: underline;
  }
  .media-print & {
    cursor: default;
    color: inherit;
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

.loading {
  position: sticky;
  > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: -16px -24px 0 -24px;
    height: 4px;
    color: #7bb9f0;
    overflow: hidden;
  }
}

.list-row {
  span:nth-child(2) {
    display: grid;
    grid-auto-columns: auto;
  }
}
.light-tabletitle {
  font-size: 0.9em;
  font-weight: 600;
  margin-bottom: 0.5em;
}
.light-tablelist {
  & > table {
    & > tbody > tr {
      border-top: 0;
    }
  }
  table table {
    td {
      font-size: 0.925em;
      padding: 3px 5px;
    }
    td:first-child {
      padding-left: 0;
    }
    td:last-child {
      border-right: 0;
    }
  }

}
.related-link {
  color: #2389e4;
  cursor: pointer;
  margin-right: 0.5em;
  margin-bottom: 2px;

  &:hover {
    text-decoration: underline;
  }
}