@import "../../styles/mixins.scss";
@import "../../styles/colours.scss";

.container {
  max-height: 100%;
  @include fade-in;
}

.borders {
  &:after {
    content: "";
    position: absolute;
    border: 1px solid #e0e0e0;
    top: 0;
    height: 100%;
    width: calc(100% - 2px);
    z-index: -1;
  }
}

.skeleton {
  padding: 0.75rem 1rem;
  border: 1px solid #e0e0e0;
  border-top: 1px solid transparent;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  border: 1px solid #e0e0e0;
  padding: 0.5rem 1rem;
  background-color: #fff;
  z-index: 0;
}

.pagination-button {
  height: 1.5rem;
  width: 1.5rem;
  font-size: 12px;
}

.pagination-icon {
  height: 0.75rem;
  width: 0.75rem;
}

.item {
  position: relative;
  border-radius: 0;
  border: 1px solid #e0e0e0;
  border-top: 1px solid transparent;
  // border-bottom: 1px solid #e0e0e0;
  padding: 0px;
}

.button {
  cursor: pointer;
  transition: background-color 0.2s ease-out;
}

.item:hover {
  box-sizing: border-box;
  border: 1px solid #c4c4c4;
  box-shadow: 0px 3px 12px #24272d1f;
  // position: relative;
  // margin-top: -1px;
  // padding-top: 1px;
  z-index: 2;
}

.item--selected,
.item--selected:hover,
.item--read {
  background-color: #f8f8f8;
}

.item--selected {
  overflow: visible;
  z-index: 1;
  border: 1px solid #3d98f8;
  box-shadow: 0px 3px 12px #24272d1f;

  &:hover {
    border: 1px solid #3d98f8;
  }

  &.item---selected-shadow {
    opacity: 1;
  }
}

// .item--read {
//   // background-color: rgba(0, 0, 0, 0.87 * 0.04);
// }

.item--selected-shadow {
  transition: opacity 0.2s ease;
  opacity: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  box-shadow: 10px 5px 5px red;
  height: 10px;
  z-index: 1;
}

.item-content {
  padding: 11px;
  &:last-child {
    padding-bottom: 1rem;
  }
}

.item-content--news { border-left: 2px solid $color-news; }
.item-content--compliance { border-left: 2px solid $color-compliance; }
.item-content--records { border-left: 2px solid $color-records; }

.item-header {
  display: flex;
  justify-content: space-between;
}

.item-header__actions {
  // display: flex;
  // align-items: center;
  padding-right: 18px;
}

.item-title {
  font-size: 14px;
  margin-right: 14px;
}

.item-title--compliance {
  font-size: 16px;
  margin-right: 14px;
}

.item-title-tags {
  white-space: nowrap;
}

.item-text {
  font-size: 12px;
  margin-top: 0.25rem;
  color: #90929a;
}

.item-text--hover {
  color: inherit;
}

.item-text-tags {
  margin-left: auto;
  white-space: nowrap;
}

.item-details--compliance {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  margin-top: 3px;
  .item-text {
    display: inline-flex;
    align-items: center;
    gap: 2px;
    color: #35383f;
    opacity: 0.8;
    font-size: 12px;
  }
  .item-title-tags {
    margin-left: 0.2em;
  }
  .item-source {
    display: block;
    font-size: 14px;
    width: 100%;
    line-height: 1.3;
    color: #35383f;
    opacity: 0.8;
  }
}

.item-details {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  .item-text {
    margin-top: 0;
    display: flex;
    align-items: center;
  }
}

.item--provider {
  display: block;
  margin-top: 5px;
  color: #90929a;
  font-size: 12px;
  svg {
    vertical-align: middle;
    margin-top: -1px;
  }
  .item-details--compliance + & {
    margin-top: -2px;
  }
}

.item--provider-label {
  display: flex;
  justify-content: right;
  font-size: x-small;
}

.findings-button {
  position: absolute;
  top: 6px;
  right: 6px;
  padding: 0;
  width: 32px;
  height: 32px;
  border: 1px solid #ebedef;
  &:hover {
    background-color: transparent;
    .findings-icon {
      display: none;
    }
    .findings-icon--unsave {
      display: inline-block;
    }
  }
}

.findings-icon--unsave {
  display: none;
  color: #fd5f1f;
}

.findings-button--unsaved {
  border: none;
  &:hover {
    background-color: rgba(#2389e4, 0.1);
    .findings-icon {
      display: inline-block;
      color: #2389e4;
    }
  }
}

.findings-icon {
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.chip {
  vertical-align: super;
}

.no-results {
  color: #90929a;
  font-size: 14px;
  text-align: center;
  width: 100%;
  display: inline-block;
  padding: 40px 2rem 2rem 2rem;
  a {
    text-decoration: underline;
    color: #111;
  }
}

.no-results-detail {
  margin: 2em 0 0.2em 0;
  color: #000;
}

.no-results-msg {
  color: #fd5f1f;
  margin: 0 0 2em 0;
}

.tooltip {
  color: #fff;
  border: 1px solid #e0e0e0;
  border-top: 0;
  border-radius: 0;
  background-color: #90929a;
  padding: 1rem;
  min-width: calc((100vw / 12) * 5);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.15);
  .item-text {
    color: #fff;
  }
}

.tooltip-placement {
  margin: 0;
}

.tooltip-title {
  font-size: 11px;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 0.25rem;
  font-weight: 500;
}

.arrow {
  font-size: 14px;
  &::before {
    background-color: #f5f6f7;
    border: 1px solid #e0e0e0;
  }
}

.retry-search {
  color: #0000EE;
  text-decoration: underline;
  cursor: pointer;
}

.no-results .support-link {
  color: #0000EE;
  text-decoration: underline;
  cursor: pointer;
}

.original {
  padding-top: 8px;
  padding-left: 12px;
  position: relative;
  &:before {
    content: "";
    background-color: #e0e0e0;
    margin-top: 8px;
    margin-bottom: 8px;
    height: calc(100% - 8px);
    width: 4px;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.translated {
  color: #2389e4;
  font-size: 12px;
  margin-top: 6px;
}
