@import "../../../styles//mixins.scss";

.label {
  text-transform: uppercase;
  font-size: 10px;
  padding: 8px 12px;
  color: #35383f;
  white-space: nowrap;
  &:first-child {
    padding-left: 12px;
  }
}

.row {
  &:hover {
    .actions {
      opacity: 1;
      transition: opacity 0.2s ease;
    }
  }
}

.row--clickable {
  &:hover {
    cursor: pointer;
  }
}

.row--animate {
  // opacity: 0;
  @include fade-in;
}

.row--tight {
  text-transform: capitalize;
  .cell {
    padding: 0.75rem 1rem;
  }

  .cell:first-child {
    padding-left: 0;
  }
}

.cell {
  padding: 12px;
}

.cell--small {
  font-size: 13px;
}

.cell--bold {
  font-weight: 600;
}

.cell--nowrap {
  white-space: nowrap;
}

.actions {
  opacity: 0;
}

.cell {
  &:first-child {
    padding-left: 12px;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  opacity: 0;
}

.pagination {
  margin-right: 4px;
  button {
    width: 40px;
    height: 40px;
  }
}
