@import "../../styles/mixins.scss";

.container {
  position: relative;
  overflow: hidden;
  padding: 36px 0 57px 0;
  // min-height: 100%;
  @include fade-in;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding: 0.25rem 1rem 0.25rem 25px;
  width: 100%;
  z-index: 1;
  font-size: 11px;
  text-transform: uppercase;
  color: #90929a;
  height: 36px;
  position: absolute;
  top: 0;
}

.wrapper {
  min-height: 100%;
  max-height: 100%;
  max-width: 100%;
}

.button-container {
  position: absolute;
  bottom: -1px;
  padding: 12px 12px 16px 25px;
  border-top: 1px solid #e0e0e0;
  width: 100%;
}

.accordion {
  background-color: transparent;
  box-shadow: none;
}

.accordion-details {
  display: flex;
  flex-direction: column;
  margin-left: 25px !important;
}

.accordion-summary {
  margin: 0;
  div {
    margin: 0;
  }
}

.facet {
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.facet-button {
  font-size: 16px;
  &:hover {
    cursor: pointer;
  }
}

.current-facets {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 10px;
  margin-right: 25px;
  padding-bottom: 10px;
}

.facet-label {
  display: block;
  font-size: 12px;
  color: #90929a;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.checkbox-controls {
  font-size: 12px;
  color: #2389e4;
  display: flex;
  margin-top: 4px;
}

.checkbox-control {
  cursor: pointer;
  margin-right: 6px;
}

.exclude {
  width: 1rem;
  height: 1rem;
  opacity: 0;
  transition: opacity 0.2s ease;
  cursor: pointer;
  margin-right: 1px;
}

.excluded {
  color: #90929a;
  text-decoration: line-through;
}

.heading {
  font-size: 14px;
  color: #35383f;
  padding: 1.2rem 0 1rem 0;
  padding-left: 25px;
}

.facets {
  margin-bottom: 1rem;
  order: 2;
  max-width: 100%;
  &:not(:first-child) {
    border-top: 1px solid #e0e0e0;
  }
}

.facets--date {
  order: 1;
  border-top: 0;
}

.label {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-left: 0.25rem;
  color: #90929a;
  &:focus {
    color: #90929a;
  }
}

.label-display {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 120px;
  margin-right: 4px;
}

.dates {
  display: flex;
  color: #90929a;
  font-size: 14px;
}

.selected {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.menu {
  border: 1px solid #e0e0e0;
  border-radius: 0;
  box-shadow: none;
}
