.chip {
  font-size: 12px;
  margin-right: 4px;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
}

.chip--small {
  font-size: 10px;
  height: 18px;

  .label {
    font-size: inherit;
    padding: 4px 6px;
  }
}

.chip--blue {
  border: none;
  color: #2389e4;
  background-color: #e9f3fc;
  &.clickable:hover,
  &.clickable:focus {
    background-color: #d2e8fc;
  }
}

.chip--grey {
  border: none;
  // color: #90929a;
  color: rgba(#35383f, 0.8);
  background-color: #e4e4e6cb;
}

.chip--dark-grey {
  border: none;
  color: #fff;
  background-color: #90929a;
}
