.container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0;
  border-top: 1px solid #e0e0e0;
  min-height: 0;
  background-color: #fff;
}

.inner {
  width: 50em;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}
