.container {
  position: fixed;
  background-color: #f5f6f7;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}

.main {}

.main--search {
  min-height: 0;
  display: grid;
  grid-template-rows: min-content min-content;
}

.main--findings {
  min-height: 0;
  display: grid;
  grid-template-rows: min-content;
}

.main--two-column {
  display: grid;
  grid-template-columns: 8fr 5fr;
  grid-gap: 35px;
  padding: 48px 45px 45px 45px;
}

.main--one-column {
  min-height: 0;
  margin: 48px 45px 45px 45px;
}

.header {
  color: #fff;
  &:before {
    content: "";
    height: 3px;
    width: 100%;
    position: absolute;
    top: 0;
    background: linear-gradient(45deg, #f52428 10%, #fb6020 80%);
  }
}

.nav {
  padding: calc(0.7rem + 2px) 1.5rem 0.7rem 1.5rem;

  background-color: #24272d;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sub-nav {
  padding: 0 1.5rem;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
}

.logo {
  display: inline-flex;
  transition: 0.2s ease;
  // padding: 1rem 0;
  &:hover {
    opacity: 0.7;
  }
  svg {
    display: flex;       
    align-items: center; 
  }
  span {
    display: flex;
    align-items: center;
    opacity: 0.9;
    margin-left: 0.7em;
    line-height: 14px;
    font-size: 16px;
    display: flex;       
    align-items: center; 
    margin-top: -1px;
  }
}

.nav-actions {
  display: flex;
  align-items: center;
}

.settings-button {
  color: #fff;
  font-size: 15px;
  width: 24px;
  height: 24px;
  // margin-top: 1px;

  &:hover {
    background-color: rgba(112, 112, 112, 0.25);
  }
}

.hidden-button {
  display: none;
}

.menu-item {
  font-size: 14px;
}

.breadcrumb-wrapper {
  display: inline-block;
  margin-left: 21px;
}

.breadcrumbs {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.breadcrumb {
  max-width: 300px;
  padding-right: 7px;
  display: flex;
  align-items: center;

  @media (max-width: 650px) {
    max-width: 20vw;
  }

  a {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &:not(:last-child) {
    color: rgba(#fff, 0.5);
    &:after {
      content: "/";
      padding-left: 7px;
    }
  }
}
