
.fa-inline-icon {
  cursor: pointer;
  width: 12px;
  height: 12px;
  margin-bottom: -1px;
}

.fa-inline-icon--bigger {
  cursor: pointer;
  width: 14px;
  height: 14px;
  margin-bottom: -2px;
}

.fa-inline-icon--angle-arrow {
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin-bottom: -2px;
}
