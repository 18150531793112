.container,
.popper {
  width: 50rem;
  position: relative;
  z-index: 3;

  @media (max-width: 1400px) {
    width: 40rem;
  }
  @media (max-width: 1200px) {
    width: 35rem;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
}



.popper {
  padding: 1.2rem 2rem 2rem 2rem;
  margin-top: -2px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-top: 1px transparent;
  border-radius: 0 0px 6px 6px;
  min-height: 15rem;
}

.popper--multi-search {
  padding-top: 8px;
}

.popper__title {
  color: rgba(#35383f, 0.5);
}

.popper__title--req {
  color: #d00;
}

.project-fields {
  margin-top: 1.1rem;
  margin-bottom: 1.2rem;
  position: relative;
  .popper__title {
    margin-bottom: .6rem;
  }
  input[type="text"] {
    padding-right: 100px;
  }
}

.project-fields__private {
  position: absolute;
  right: 0;
  top: 2.8rem;
  z-index: 1;
}

.providers {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .provider-category {
    text-transform: uppercase;
    font-size: 12px;
    opacity: 0.8;
  }

  .provider-notice {
    display: block;
    color: red;
    font-size: 12px;
    opacity: 0.8;
    font-weight: 600;
  }

  .provider-item {
    margin-top: 0.75rem;
    margin-left: 1.5rem;
  }

  .provider-item__label-icon {
    display: flex;
    gap: 4px;
  }

  .provider-item__label {
    font-size: 15px;
    margin-left: 3px;
  }

  .provider-item__terms {
    color: rgba(#35383f, 0.5);
    margin-left: 6px;
    font-size: 12px;
  }
}

.terms-container {
  z-index: 10;
  top: 0;
  left: 0;
  position: absolute;
  background-color: rgba(#fff, 0.8);
  border: 1px solid #e0e0e0;
  border-top: 1px solid transparent;
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

.terms-wrapper {
  max-height: 100%;
  min-height: 100%;
  margin-left: auto;
  width: 275px;
  box-shadow: 0px 6px 18px #24272d1f;
  border-left: 1px solid #e0e0e0;
  overflow: scroll;
}

.terms-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid #e0e0e0;
  .terms-header__title {
    font-size: 10px;
    color: regba(#35383f, 0.8);
    text-transform: uppercase;
  }
}

.accordion {
  color: rgba(#35383f, 0.8);
  font-size: 12px;
  text-transform: uppercase;
}

.button {
  margin-top: 1.5rem;
}
.button + label {
  margin-top: 1rem;
}

.terms {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.terms-category {
  text-transform: uppercase;
  font-size: 12px;
  opacity: 0.8;
}

.terms-label {
  width: 100%; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 8px;
}

.term {
  border: 1px solid #e0e0e0;
  padding: 4px 6px;
  border-radius: 3px;
  span {
    color: #000;
  }
}

.input {
  background: white;

  textarea[name="search"] {
    resize: none;
    padding-right: 100px;
  }

  fieldset {
    border-radius: 4px;
  }
}
.input--active {
  fieldset {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.input--multi {
  textarea[name="search"] {
    resize: vertical !important;
    min-height: 100px;
    max-height: 200px;
    //overflow-y: scroll !important;
  }
}

.helper-text {
  margin: 2px 0 0;

  &--active {
    padding: 5px 5px 0 2rem;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
  }
}

.multi-dropdown {
  position: absolute;
  top: 3px;
  right: 0;
  display: flex;
  align-items: center;
  color: #555;

  &--flex {
    display: flex;
    align-items: center;
  }

  &--active {
    color: #0f68b7;
  }

  &--tooltip {
    color: #555;
    padding-bottom: 1px;
    margin-right: 10px;
    margin-left: -8px;
  }

  button {
    color: currentColor;
    background: transparent !important;
  }
}

.req-fields-msg {
  margin-left: 1em;
  font-size: 80%;
  opacity: 0.8;
}
